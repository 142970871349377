import 'react-quill/dist/quill.snow.css';

import * as React from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import { PrimaryButton } from '../components/form/primaryButton';
import { AppDispatch } from '../store';
import { welcomeMessageSelector } from '../store/selectors/events.selectors';
import { getWelcomeMessage, updateWelcomeMessage } from '../store/slices/events.slice';

const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link'],
        ['clean']
    ],
};

const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link',
];

interface IEmailTemplateProps {
}

const EmailTemplate: React.FunctionComponent<IEmailTemplateProps> = (props) => {
    const [value, setValue] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const welcomeMessage = useSelector(welcomeMessageSelector);
    React.useEffect(() => {
        setValue(welcomeMessage || '');
    }, [welcomeMessage])
    const dispatch = useDispatch<AppDispatch>();
    React.useEffect(() => {
        dispatch(getWelcomeMessage({}));
    }, [])
    const handleSubmit = () => {
        setIsLoading(true);
        dispatch(updateWelcomeMessage({
            message: value,
            onSuccess: () => setIsLoading(false)
        }))
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }
    const handleReset = () => {
        setValue(welcomeMessage || '');
    }
    return (
        <div className="mt-4 flex flex-col gap-4 bg-white p-8 rounded-md shadow-md">
            <h1 className="text-2xl font-bold">
                Edit your email template</h1>
            <ReactQuill
                value={value}
                onChange={setValue}
                modules={modules}
                formats={formats}
                placeholder='Compose your email here...'
                className='min-h-[400px]'
            />
            <p className='text-sm text-gray-500'>
                {`Note: The variables represented as {{email}} {{password}} etc are for placeholders which will be substituted when the email is sent`}
            </p>
            <div className='flex flex-row items-center justify-end gap-2'>
                {/* Button to reset the vallue */}
                <Button onClick={handleReset}>RESET FORM</Button>
                <PrimaryButton
                    label="SAVE MY TEMPLATE"
                    onClick={() => handleSubmit()}
                    isLoading={isLoading}
                />
            </div>
        </div>
    )
};

export default EmailTemplate;
