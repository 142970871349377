import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Event } from '../../models/event';
import API, { ApiResponse } from '../apiUtils';
import { setToastMessage } from './app.slice';

export type Events = {
  list: Array<Event>;
  isLoading: boolean;
  error: string;
  activeEvent: Event | undefined;
  welcomeMessage?: string;
};

const initialState: Events = {
  list: [],
  isLoading: false,
  error: '',
  activeEvent: undefined,
  welcomeMessage: ''
};

const createEvent = createAsyncThunk(
  'events/create',
  async (
    {
      event,
      logo,
      map,
      schedule,
      onSuccess,
    }: {
      event: Event;
      logo?: File;
      map?: File;
      schedule?: File;
      onSuccess: () => void;
    },
    thunkApi
  ) => {
    try {
      const logoUploadResponse: any = logo && (await uploadFile(logo!));
      const mapUploadResponse: any = map && (await uploadFile(map!));
      const scheduleUploadResponse: any =
        schedule && (await uploadFile(schedule!));
      console.log({ logoUploadResponse, mapUploadResponse });
      if (!logo) {
        delete event.logo;
      }
      if (!map) {
        delete event.map;
      }
      if (!schedule) {
        delete event.schedule;
      }
      const response = await API.post<ApiResponse<Event>, Event>('/events', {
        ...event,
        ...(logoUploadResponse && { logo: logoUploadResponse.id }),
        ...(mapUploadResponse && { map: mapUploadResponse.id }),
        ...(scheduleUploadResponse && {
          schedule: scheduleUploadResponse.id,
        }),
      });
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('Event created successfully'));
      return response;
    } catch (error) {
      console.log(error);
      return thunkApi.rejectWithValue(error);
    }
  }
);

type FileUploadResponse = {
  id: string;
  name: string;
};

/**
 * logoUploadResponse
: 
{id: 12, name: '30c57b44-a446-41e8-8134-d0d2609ba275apollo-tyres-brand-logo.png'}
mapUploadResponse
: 
{id: 13, name: 'ea902569-8307-4ba5-b2b4-2d3cbfc3f817apollo-tyres-brand-logo.png'}
 */

const uploadFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await API.postMultipartFormData<
    ApiResponse<FileUploadResponse>,
    FormData
  >('/files', formData);
  return response;
};

const getEvents = createAsyncThunk(
  'events/get',
  async (payload: any = {}, thunkApi) => {
    try {
      const response = await API.get<ApiResponse<Event[]>>(
        '/events?limit=0&offset=0'
      );
      console.log(response);
      return response;
    } catch (error) {
      thunkApi.dispatch(setToastMessage('Error fetching events'));
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getActiveEvent = createAsyncThunk(
  'events/active',
  async (payload: any = {}, thunkApi) => {
    try {
      const response = await API.get<ApiResponse<Event>>('/events?status=true');
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateEvent = createAsyncThunk(
  'events/update',
  async (
    {
      event,
      logo,
      map,
      schedule,
      onSuccess,
    }: {
      event: Event;
      logo?: File;
      map?: File;
      schedule?: File;
      onSuccess: () => void;
    },
    thunkApi
  ) => {
    try {
      const logoUploadResponse: any = logo && (await uploadFile(logo!));
      const mapUploadResponse: any = map && (await uploadFile(map!));
      const scheduleUploadResponse: any =
        schedule && (await uploadFile(schedule!));
      console.log({ logoUploadResponse, mapUploadResponse });
      if (!logo) {
        delete event.logo;
      }
      if (!map) {
        delete event.map;
      }
      if (!schedule) {
        delete event.schedule;
      }
      const response = await API.patch<ApiResponse<Event>, Event>(
        `/events/${event.id}`,
        {
          ...event,
          ...(logoUploadResponse && { logo: logoUploadResponse.id }),
          ...(mapUploadResponse && { map: mapUploadResponse.id }),
          ...(scheduleUploadResponse && {
            schedule: scheduleUploadResponse.id,
          }),
        }
      );
      thunkApi.dispatch(setToastMessage('Event updated successfully'));
      const state: any = thunkApi.getState();
      if (state.events.activeEvent?.id === event.id) {
        thunkApi.dispatch(getActiveEvent({}));
      }
      thunkApi.dispatch(getEvents({}));
      onSuccess && onSuccess();
      return response;
    } catch (error) {
      console.log(error);
      return thunkApi.rejectWithValue(error);
    }
  }
);

// Write a thunk action for /events/welcome-message
export const getWelcomeMessage = createAsyncThunk(
  'events/welcome-message',
  async (payload: any = {}, thunkApi) => {
    try {
      const response = await API.get<ApiResponse<string>>(
        '/events/welcome-message'
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

// write a post method /events/welcome-message that takes an object as payment which has one prop message
export const updateWelcomeMessage = createAsyncThunk(
  'events/welcome-message/update',
  async (
    { message, onSuccess }: { message: string; onSuccess: () => void },
    thunkApi
  ) => {
    try {
      const response = await API.post<ApiResponse<string>, { message: string }>(
        '/events/welcome-message',
        { message }
      );
      thunkApi.dispatch(setToastMessage('Welcome message updated successfully'));
      onSuccess && onSuccess();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    resetEventError: (state) => {
      state.error = '';
    },
  },
  extraReducers(builder) {
    builder.addCase(createEvent.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(createEvent.fulfilled, (state, action) => {
      console.log(action);
      const payload: any = action.payload;
      state.isLoading = false;
      state.list.push(payload);
    });
    builder.addCase(createEvent.rejected, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    builder.addCase(getEvents.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getEvents.fulfilled, (state, action) => {
      console.log(action);
      const payload: any = action.payload;
      state.isLoading = false;
      state.list = payload;
    });
    builder.addCase(getEvents.rejected, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    builder.addCase(updateEvent.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(updateEvent.fulfilled, (state, action) => {
      console.log(action);
      state.isLoading = false;
      const evnt = action.meta.arg.event;
      state.list = state.list.map((event) =>
        event.id === evnt.id ? evnt : event
      );
    });
    builder.addCase(updateEvent.rejected, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    builder.addCase(getActiveEvent.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getActiveEvent.fulfilled, (state, action) => {
      console.log(action);
      const payload: any = action.payload;
      state.isLoading = false;
      state.activeEvent = payload?.length > 0 ? payload[0] : undefined;
    });
    builder.addCase(getActiveEvent.rejected, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    builder.addCase(getWelcomeMessage.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    }
    );
    builder.addCase(getWelcomeMessage.fulfilled, (state, action) => {
      console.log(action);
      const payload: any = action.payload.message;
      state.isLoading = false;
      state.welcomeMessage = payload;
    });
    builder.addCase(getWelcomeMessage.rejected, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
  },
});

const { resetEventError } = eventsSlice.actions;

export {
  createEvent,
  getEvents,
  initialState,
  resetEventError,
  getActiveEvent,
};

export const eventsReducer = eventsSlice.reducer;
