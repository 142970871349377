import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { User } from '../../models/user';
import API, { ApiResponse } from '../apiUtils';
import { setToastMessage } from './app.slice';
import { getLoggedInUserProfile } from './auth.slice';

interface UsersState {
  list: User[];
  isLoading: boolean;
  error: string;
  importResponse?: ImportResponse;
}

export interface ImportResponse {
  successfullyAdded: number;
  errorEmails: string[];
  duplicateEmails: string[];
  errorList: {
    [key: string]: string[];
  };
}

const initialState: UsersState = {
  list: [],
  isLoading: false,
  error: '',
  importResponse: undefined,
};

const createUser = createAsyncThunk(
  'users/create',
  async (
    { user, onSuccess }: { user: User; onSuccess: () => void },
    thunkApi
  ) => {
    try {
      const response = await API.post<ApiResponse<User>, User>('/users', user);
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('User created successfully'));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getUsers = createAsyncThunk(
  'users/list',
  async (payload: any = {}, thunkApi) => {
    try {
      const response = await API.get<ApiResponse<User[]>>(
        `/users?limit=0&offset=0${
          payload.queryParams ? '&' + payload.queryParams : ''
        }`
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getUserById = createAsyncThunk(
  'users/getById',
  async ({ id }: { id: number }, thunkApi) => {
    try {
      const response = await API.get<ApiResponse<User>>(`/users/${id}`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateUser = createAsyncThunk(
  'users/update',
  async (
    { user, onSuccess }: { user: Partial<User>; onSuccess?: () => void },
    thunkApi
  ) => {
    try {
      await API.patch<ApiResponse<User>, Partial<User>>(
        `/users/${user.id}`,
        user
      );
      const state: any = thunkApi.getState();
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('User updated successfully'));
      if (user.id === state.auth.user?.id)
        thunkApi.dispatch(getLoggedInUserProfile({ id: user.id as number }));
      return { ...user };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

// Action to send reset-password link /users/{id}/reset-password
const resetPassword = createAsyncThunk(
  'users/resetPassword',
  async (
    { id, onSuccess }: { id: number; onSuccess: () => void },
    thunkApi
  ) => {
    try {
      await API.post<ApiResponse<User>, undefined>(`/users/${id}/reset-password`,undefined);
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('Password has been sent to the email.'));
    } catch (error) {
      thunkApi.dispatch(setToastMessage('Error in sending password to the email.'));
      return thunkApi.rejectWithValue(error);
    }
  }
);

// Action to unblock user /users/{id}/unblock-user
const unblockUser = createAsyncThunk(
  'users/unblockUser',
  async (
    { id, onSuccess }: { id: number; onSuccess: () => void },
    thunkApi
  ) => {
    try {
      await API.post<ApiResponse<User>, undefined>(`/users/${id}/unblock-user`, undefined);
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('User unblocked successfully.'));
    } catch (error) {
      thunkApi.dispatch(setToastMessage('Error in unblocking the user.'));
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteUser = createAsyncThunk(
  'users/delete',
  async (
    { id, onSuccess }: { id: number; onSuccess: () => void },
    thunkApi
  ) => {
    try {
      await API.delete<ApiResponse<User>>(`/users/${id}`);
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('User deleted successfully'));
      return { id };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

//Create a thunk action to import users from a csv file
const importUsers = createAsyncThunk(
  'users/import',
  async (
    { file, onSuccess }: { file: File; onSuccess: () => void },
    thunkApi
  ) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await API.postMultipartFormData<
        ApiResponse<User[]>,
        FormData
      >('/import-users', formData);
      thunkApi.dispatch(getUsers({}));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearImportResponse(state) {
      state.importResponse = undefined;
    },
    resetUserError(state) {
      state.error = '';
    },
    resetUsers(state) {
      state.list = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(createUser.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.list.push(payload);
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });

    //Get users
    builder.addCase(getUsers.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.list = payload;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });

    //Update users
    builder.addCase(updateUser.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.list = state.list.map((user) => {
        if (user.id === payload.id)
          return {
            ...user,
            ...payload,
          };
        return user;
      });
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    builder.addCase(deleteUser.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.list = state.list.filter((user) => user.id !== payload.id);
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    builder.addCase(importUsers.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
      state.importResponse = undefined;
    });
    builder.addCase(importUsers.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.importResponse = payload;
    });
    builder.addCase(importUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    builder.addCase(getUserById.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getUserById.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      //Check if it exists in the list and update it or else append to the list
      const index = state.list.findIndex((user) => user.id === payload.id);
      if (index !== -1) {
        state.list[index] = payload;
      } else {
        state.list.push(payload);
      }
    });
    builder.addCase(unblockUser.fulfilled, (state, action) => {
      state.isLoading = false;
      // find the user and update blocked to false
      state.list = state.list.map((user) => {
        if (user.id === action.meta.arg.id) {
          return {
            ...user,
            blocked: false,
          };
        }
        return user;
      });
    });
  },
});

const { clearImportResponse, resetUserError, resetUsers } = usersSlice.actions;

export {
  createUser,
  getUsers,
  updateUser,
  deleteUser,
  importUsers,
  clearImportResponse,
  initialState,
  resetUserError,
  resetUsers,
  getUserById,
  resetPassword,
  unblockUser
};

export const userReducer = usersSlice.reducer;
