import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import API, { ApiResponse } from '../apiUtils';
import { setToastMessage } from './app.slice';

export type FAQ = {
    question: string;
    answer: string;
};

interface FaqsState {
  list: FAQ[];
  isLoading: boolean;
  error: string;
}

const initialState: FaqsState = {
  list: [],
  isLoading: false,
  error: '',
};

const getFaqs = createAsyncThunk(
  'faqs/getFaqs',
  async (_, thunkApi) => {
    try {
      const response = await API.get<FAQ[]>('/faqs');
      console.log({ response })
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const postFaqs = createAsyncThunk(
  'faqs/postFaqs',
  async ({ faqs, onSuccess }: { faqs: FAQ[]; onSuccess: () => void }, thunkApi) => {
    try {
      const response = await API.post<ApiResponse<FAQ[]>, FAQ[]>('/faqs', faqs);
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('FAQs updated successfully'));
      return response;
    } catch (error) {
        thunkApi.dispatch(setToastMessage('Failed to update FAQs'));
      return thunkApi.rejectWithValue(error);
    }
  }
);

const faqsSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getFaqs.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getFaqs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload ;
    });
    builder.addCase(getFaqs.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    builder.addCase(postFaqs.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(postFaqs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.meta.arg.faqs;
    });
    builder.addCase(postFaqs.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
  },
});

export { getFaqs, postFaqs };
export const faqsReducer = faqsSlice.reducer;
