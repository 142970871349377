import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, TextField
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

import { roles } from '../appRoutes';
import { PrimaryButton } from '../components/form/primaryButton';
import { FAQ } from '../data/faqs';
import useAllowForRole from '../hooks/useAllowForRole';
import useFaqs from '../hooks/useFaqs';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  margin: theme.spacing(3),

  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  color: theme.palette.grey[600],
}));

export default function CustomizedAccordions() {
  const allowEdits = useAllowForRole([roles.SUPER_ADMIN]);
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const { faqs, isLoading, updateFaqs } = useFaqs();
  const [open, setOpen] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState<number | null>(null);
  const [question, setQuestion] = React.useState('');
  const [answer, setAnswer] = React.useState('');
  const [errors, setErrors] = React.useState<{ question?: string; answer?: string }>({});

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleOpen = (index: number | null = null) => {
    if (index !== null) {
      setQuestion(faqs[index].question);
      setAnswer(faqs[index].answer);
      setEditIndex(index);
    } else {
      setQuestion('');
      setAnswer('');
      setEditIndex(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
  };

  const handleSave = () => {
    const newErrors: { question?: string; answer?: string } = {};
    if (!question.trim()) newErrors.question = 'Question is required';
    if (!answer.trim()) newErrors.answer = 'Answer is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const updatedFaqs = [...faqs];
    if (editIndex !== null) {
      updatedFaqs[editIndex] = { question, answer };
    } else {
      updatedFaqs.push({ question, answer });
    }
    updateFaqs(updatedFaqs, handleClose);
  };

  const handleDelete = (index: number) => {
    const updatedFaqs = faqs.filter((_, i) => i !== index);
    updateFaqs(updatedFaqs, () => { });
  };

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(e.target.value);
    if (errors.question) {
      setErrors((prevErrors) => ({ ...prevErrors, question: undefined }));
    }
  };

  const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer(e.target.value);
    if (errors.answer) {
      setErrors((prevErrors) => ({ ...prevErrors, answer: undefined }));
    }
  };

  return (
    <Paper className="m-4">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1 className="text-2xl px-4 py-8 font-bold">
          Frequently Asked Questions:
        </h1>
        {allowEdits && (
          <IconButton color="primary" onClick={() => handleOpen()} style={{ marginRight: '2rem' }}>
            <AddIcon htmlColor='grey' />
          </IconButton>
        )}
      </Box>
      <Divider />
      {faqs.map((faq: FAQ, index: number) => {
        return (
          <Accordion
            key={`faq-${index}`}
            expanded={expanded === index.toString()}
            onChange={handleChange(index.toString())}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>{faq.question}</Typography>
              {allowEdits && (
                <Box display="flex" marginLeft="auto">
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpen(index);
                    }}
                  >
                    <EditIcon htmlColor='grey' />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(index);
                    }}
                  >
                    <DeleteIcon htmlColor='red' />
                  </IconButton>
                </Box>
              )}
            </AccordionSummary>
            <AccordionDetails className="text-grey-300">
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editIndex !== null ? 'Edit FAQ' : 'Add FAQ'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Question"
            type="text"
            fullWidth
            multiline
            rows={2}
            value={question}
            onChange={handleQuestionChange}
            error={!!errors.question}
            helperText={errors.question}
          />
          <TextField
            margin="dense"
            label="Answer"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={answer}
            onChange={handleAnswerChange}
            error={!!errors.answer}
            helperText={errors.answer}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            CANCEL
          </Button>
          <PrimaryButton onClick={handleSave} color="primary" label='SAVE' isLoading={isLoading} disabled={isLoading} />
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
