import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../store';
import { selectFaqs, selectFaqsLoading } from '../store/selectors/faqs.selectors';
import { FAQ, getFaqs, postFaqs } from '../store/slices/faqs.slice';

const useFaqs = () => {
  const dispatch = useDispatch<AppDispatch>();
  const faqs = useSelector(selectFaqs);
    const isLoading = useSelector(selectFaqsLoading);
  useEffect(() => {
    dispatch(getFaqs());
  }, [dispatch]);

  const updateFaqs = (faqs: FAQ[], onSuccess: () => void) => {
    dispatch(postFaqs({ faqs, onSuccess }));
  };

  return { faqs: faqs || [],isLoading,  updateFaqs };
};

export default useFaqs;
