import {
    Assessment, ChatOutlined, DashboardOutlined, Diversity1, EmailOutlined, EventSeat,
    GroupsOutlined, PeopleOutlined, School, ThreeP
} from '@mui/icons-material';

import ChatScreen from './screens/chat';
import Committees from './screens/committees';
import Dashboard from './screens/dashboard';
import EmailTemplate from './screens/emailTemplate';
import Events from './screens/events';
import EventUsers from './screens/eventUsers';
import Faqs from './screens/faqs';
import ForgotPassword from './screens/forgotPassword';
import Groups from './screens/groups';
import Login from './screens/login';
import Maps from './screens/maps';
import MonitorChats from './screens/monitorChats';
import MyAccount from './screens/myAccount';
import MyProfile from './screens/myProfile';
import Reports from './screens/reports';
import Schedule from './screens/schedule';
import Schools from './screens/schools';
import Settings from './screens/settings';
import UpdatePassword from './screens/updatePassword';

export const roles = {
  SUPER_ADMIN: '1',
  MODERATOR: '2',
  CHAIR: '3',
  DEPUTY_CHAIR: '4',
  DELEGATE: '5',
  OBSERVER: '6',
  // Add other roles as needed
};
export const allRoles = [
  roles.SUPER_ADMIN,
  roles.DELEGATE,
  roles.MODERATOR,
  roles.CHAIR,
  roles.DEPUTY_CHAIR,
  roles.OBSERVER,
];
export const routePath = {
  home: '/',
  login: '/login',
  admin: '/admin',
  forgotPassword: '/forgot-password',
  events: '/events',
  chat: '/chat',
  myProfile: '/my-profile',
  users: '/users',
  committees: '/committees',
  groups: '/groups',
  schools: '/schools',
  settings: '/settings',
  myAccount: '/my-account',
  updatePassword: '/update-password',
  faqs: '/faqs',
  maps: '/maps',
  schedule: '/schedule',
  reports: '/reports',
  monitorChats: '/monitor-chats',
  emailTemplate: '/email-template',
};
export const appRoutes = [
  {
    path: routePath.home,
    component: <Dashboard />,
    allowedRoles: allRoles,
    restrictedAccess: true,
    label: 'Dashboard',
    icon: () => <DashboardOutlined />,
  },
  {
    path: routePath.login,
    component: <Login />,
    allowedRoles: [],
    restrictedAccess: false,
  },
  {
    path: routePath.forgotPassword,
    component: <ForgotPassword />,
    allowedRoles: [],
    restrictedAccess: false,
  },

  {
    path: routePath.myProfile,
    component: <MyProfile />,
    allowedRoles: [
      roles.SUPER_ADMIN,
      roles.MODERATOR,
      roles.CHAIR,
      roles.DEPUTY_CHAIR,
      roles.DELEGATE,
      roles.OBSERVER,
    ],
  },
  {
    path: routePath.events,
    component: <Events />,
    allowedRoles: [roles.SUPER_ADMIN],
    restrictedAccess: true,
    label: 'Events',
    icon: () => <EventSeat />,
  },
  {
    path: routePath.users,
    component: <EventUsers />,
    allowedRoles: [roles.SUPER_ADMIN, roles.MODERATOR],
    restrictedAccess: true,
    label: 'Users',
    icon: () => <PeopleOutlined />,
  },
  {
    path: routePath.committees,
    component: <Committees />,
    allowedRoles: [roles.SUPER_ADMIN],
    restrictedAccess: true,
    label: 'Committees',
    icon: () => <Diversity1 />,
  },
  {
    path: routePath.groups,
    component: <Groups />,
    allowedRoles: [roles.SUPER_ADMIN],
    restrictedAccess: true,
    label: 'Groups',
    icon: () => <GroupsOutlined />,
  },
  {
    path: routePath.schools,
    component: <Schools />,
    allowedRoles: [roles.SUPER_ADMIN],
    restrictedAccess: true,
    label: 'Schools',
    icon: () => <School />,
  },
  {
    path: routePath.myAccount,
    component: <MyAccount />,
    allowedRoles: [
      roles.SUPER_ADMIN,
      roles.MODERATOR,
      roles.CHAIR,
      roles.DEPUTY_CHAIR,
      roles.DELEGATE,
      roles.OBSERVER,
    ],
    restrictedAccess: true,
    children: [
      {
        path: `${routePath.myAccount}${routePath.myProfile}`,
        component: <MyProfile />,
        allowedRoles: [
          roles.SUPER_ADMIN,
          roles.MODERATOR,
          roles.CHAIR,
          roles.DEPUTY_CHAIR,
          roles.DELEGATE,
          roles.OBSERVER,
        ],
        restrictedAccess: true,
      },
      {
        path: `${routePath.myAccount}${routePath.settings}`,
        component: <Settings />,
        allowedRoles: [
          roles.SUPER_ADMIN,
          roles.MODERATOR,
          roles.CHAIR,
          roles.DEPUTY_CHAIR,
          roles.DELEGATE,
          roles.OBSERVER,
        ],
        restrictedAccess: true,
      },
      {
        path: `${routePath.myAccount}${routePath.updatePassword}`,
        component: <UpdatePassword />,
        allowedRoles: [
          roles.SUPER_ADMIN,
          roles.MODERATOR,
          roles.CHAIR,
          roles.DEPUTY_CHAIR,
          roles.DELEGATE,
          roles.OBSERVER,
        ],
        restrictedAccess: true,
      },
    ],
  },
  {
    path: routePath.chat,
    component: <ChatScreen />,
    allowedRoles: [
      roles.SUPER_ADMIN,
      roles.MODERATOR,
      roles.CHAIR,
      roles.DELEGATE,
      roles.OBSERVER,
      roles.DEPUTY_CHAIR,
    ],
    restrictedAccess: true,
    label: 'Chat',
    icon: () => <ChatOutlined />,
  },
  {
    path: routePath.faqs,
    component: <Faqs />,
    allowedRoles: allRoles,
    restrictedAccess: false,
  },
  {
    path: routePath.maps,
    component: <Maps />,
    allowedRoles: allRoles,
    restrictedAccess: true,
  },
  {
    path: routePath.schedule,
    component: <Schedule />,
    allowedRoles: allRoles,
    restrictedAccess: true,
  },
  {
    path: routePath.reports,
    component: <Reports />,
    allowedRoles: [roles.SUPER_ADMIN],
    restrictedAccess: true,
    label: 'Reports',
    icon: () => <Assessment />,
  },
  {
    path: routePath.monitorChats,
    component: <MonitorChats />,
    allowedRoles: [roles.SUPER_ADMIN, roles.MODERATOR, roles.CHAIR],
    restrictedAccess: true,
    label: 'Monitor Chats',
    icon: () => <ThreeP />,
  },
  {
    path: routePath.emailTemplate,
    component: <EmailTemplate />,
    allowedRoles: [roles.SUPER_ADMIN],
    restrictedAccess: true,
    label: 'Email Template',
    icon: () => <EmailOutlined />,
  }
];
