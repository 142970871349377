import {
  createRow, MRT_Cell, MRT_Column, MRT_ColumnDef, MRT_Row, MRT_RowData, MRT_TableInstance
} from 'material-react-table';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BlockOutlined, CloudUpload, Email } from '@mui/icons-material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import {
  Alert, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip
} from '@mui/material';

import { roles } from '../appRoutes';
import AddUserDialog from '../components/addUserForm';
import ConfirmAction from '../components/confirmAction';
import FileInput from '../components/fields/fileInput';
import { PrimaryButton } from '../components/form/primaryButton';
import Table from '../components/table';
import { getStatusColumn } from '../components/table/statusColumn';
import useAllowForRole from '../hooks/useAllowForRole';
import { Committee } from '../models/committee';
import { User } from '../models/user';
import { AppDispatch } from '../store';
import { countriesSelector, rolesSelector } from '../store/selectors/app.selectors';
import { committeesListSelector } from '../store/selectors/committees.selectors';
import { groupsListSelector } from '../store/selectors/group.selectors';
import { schoolsSelector } from '../store/selectors/schools.selectors';
import {
  usersErrorSelector, usersImportResponseSelector, usersIsLoadingSelector, usersSelector
} from '../store/selectors/users.selectors';
import { getRoles } from '../store/slices/app.slice';
import { getCommittees } from '../store/slices/committees.slice';
import { getGroups } from '../store/slices/groups.slice';
import { getSchools } from '../store/slices/schools.slice';
import {
  clearImportResponse, getUsers, ImportResponse, importUsers, resetPassword, resetUserError,
  unblockUser, updateUser
} from '../store/slices/users.slice';
import { nullToEmpty, resetErrorOnFocus } from '../utils/utils';
import { AdminUserContextProvider } from './adminUserContext';

interface IUsersProps {
  eventId: number;
  isActiveEvent?: boolean;
}

const Users: React.FunctionComponent<IUsersProps> = ({
  eventId,
  isActiveEvent = false,
}) => {
  const isAdminUsers = eventId === -1;
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const allowEdits =
    useAllowForRole([roles.SUPER_ADMIN]) && (isActiveEvent || isAdminUsers);
  const users = useSelector(usersSelector);
  const isLoading = useSelector(usersIsLoadingSelector);
  const error = useSelector(usersErrorSelector);
  const dispatch = useDispatch<AppDispatch>();
  const appRoles = useSelector(rolesSelector);
  const schools = useSelector(schoolsSelector);
  const groups = useSelector(groupsListSelector);
  const countries = useSelector(countriesSelector);
  const [selectedRole, setSelectedRole] = useState<number>();
  const committees = useSelector(committeesListSelector);
  const roleOptions =
    appRoles?.map((role) => ({
      label: role.name,
      value: role.id,
    })) ?? [];
  useEffect(() => {
    dispatch(getRoles({}));

    dispatch(getSchools({}));
    dispatch(getGroups({}));
    dispatch(getCommittees({}));
  }, []);
  useEffect(() => {
    dispatch(
      getUsers(eventId !== -1 ? { queryParams: `eventId=${eventId}` } : {})
    );
  }, [eventId]);
  const schoolOptions = React.useMemo(() => {
    return schools
      .filter((school) => school.status)
      .map((school) => ({
        label: school.name,
        value: school.id,
      }));
  }, [schools]);
  const groupOptions = React.useMemo(() => {
    return [
      ...groups
        .filter((group) => group.status)
        .map((group) => ({
          label: group.name,
          value: group.id,
        })),
      ...countries
        .filter((country) => country.status)
        .map((country) => ({
          label: country.name,
          value: country.id,
        })),
    ];
  }, [groups, countries]);
  const genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ];
  const columns = React.useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableEditing: false,
        enableSorting: false,
        enableColumnFilter: false,
        Edit: ({ row, table }) => {
          return <div className="pt-2"></div>;
        },
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
        },
        Cell: ({ row }: { row: MRT_Row<User> }) =>
          `${row.original.firstName} ${nullToEmpty(row.original.lastName)}`,
        Edit: ({ row, table }) => {
          return null;
        },
        filterFn: (row, id, filterValue) => {
          const name = `${row.original.firstName || ''} ${row.original.lastName || ''
            }`;
          return name?.toLocaleLowerCase()?.includes(filterValue);
        },
        sortingFn: (rowA, rowB, columnId) => {
          const nameA = `${rowA.original.firstName || ''} ${rowA.original.lastName || ''
            }`;
          const nameB = `${rowB.original.firstName || ''} ${rowB.original.lastName || ''
            }`;
          return nameA.localeCompare(nameB);
        },
      },
      {
        accessorKey: 'firstName',
        header: 'First Name',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.firstName,
          helperText: validationErrors?.firstName,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'firstName',
              validationErrors,
              setValidationErrors,
            }),
        },
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
        muiEditTextFieldProps: {
          error: !!validationErrors?.lastName,
          helperText: validationErrors?.lastName,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'lastName',
              validationErrors,
              setValidationErrors,
            }),
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.email,
          helperText: validationErrors?.email,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'email',
              validationErrors,
              setValidationErrors,
            }),
        },
      },
      {
        accessorKey: 'roleId',
        header: 'Role',
        Cell: ({ row }: { row: MRT_Row<User> }) => {
          const role = roleOptions.find(
            (role) => role.value === row.original.roleId
          );
          return (
            <div className="flex flex-wrap flex-row gap-2">{role?.label}</div>
          );
        },
        filterVariant: 'select',
        filterSelectOptions: roleOptions,
        muiEditTextFieldProps: {
          select: true,
          required: true,
          error: !!validationErrors?.roleId,
          helperText: validationErrors?.roleId,
          onChange: (e) => {
            setSelectedRole(Number(e.target.value));
          },
          onFocus: () =>
            resetErrorOnFocus({
              key: 'roleId',
              validationErrors,
              setValidationErrors,
            }),
        },
        editSelectOptions: roleOptions,
        editVariant: 'select',
        filterFn: (row, id, filterValue) => {
          return row.original.roleId === filterValue;
        },
      },
      {
        accessorKey: 'schoolId',
        header: 'School',
        muiEditTextFieldProps: {
          error: !!validationErrors?.schoolId,
          helperText: validationErrors?.schoolId,
        },
        Cell: ({ row }: { row: MRT_Row<User> }) => <p>name</p>,
        editVariant: 'select',
        editSelectOptions: schoolOptions,
        filterVariant: 'select',
        filterSelectOptions: schoolOptions,
      },
      {
        accessorKey: 'representeeId',
        header: 'Country/Group',
        size: 150,
        muiEditTextFieldProps: {
          error: !!validationErrors?.representeeId,
          helperText: validationErrors?.representeeId,
        },
        Cell: ({ row }: { row: MRT_Row<User> }) => {
          const group = groupOptions.find(
            (group) => group.value === row.original.representeeId
          );
          return (
            <div className="flex flex-wrap flex-row gap-2">
              {group?.label ?? ''}
            </div>
          );
        },
        editVariant: 'select',
        editSelectOptions: groupOptions,
        filterVariant: 'select',
        filterSelectOptions: groupOptions,
      },
      {
        accessorKey: 'phoneNumber',
        header: 'Phone Number',
        size: 150,
        muiEditTextFieldProps: {
          required: true,
          type: 'number',
          error: !!validationErrors?.phoneNumber,
          helperText: validationErrors?.phoneNumber,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'phoneNumber',
              validationErrors,
              setValidationErrors,
            }),
        },
      },
      {
        accessorKey: 'gender',
        header: 'Gender',
        editVariant: 'select',
        editSelectOptions: genderOptions,
        muiEditTextFieldProps: {
          select: true,
          required: true,
          error: !!validationErrors?.gender,
          helperText: validationErrors?.gender,
          defaultValue: false,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'gender',
              validationErrors,
              setValidationErrors,
            }),
        },
        filterVariant: 'select',
        filterSelectOptions: genderOptions,
        filterFn: (row, id, filterValue) => {
          return row.original.gender === filterValue;
        },
      },

      {
        accessorKey: 'committeeIds',
        header: 'Committees',
        editVariant: 'select',
        // enableColumnFilter: false,
        filterVariant: 'select',
        filterSelectOptions: committees.map((committee) => ({
          label: committee.name,
          value: committee.id,
        })),
        Edit: MultiSelectEditComponent(committees)<User>,
        Cell: ({ row }: { row: MRT_Row<User> }) => (
          <div className="flex flex-wrap flex-row gap-2">
            {row.original.committeeIds?.map((committeeId) => {
              const committee = committees.find(
                (committee) => committee.id === committeeId
              );
              return <Chip key={committeeId} label={committee?.name} />;
            })}
          </div>
        ),
      },

      getStatusColumn<User>(validationErrors),
    ],
    [validationErrors, roleOptions, schoolOptions, groupOptions]
  );
  const updatedColumns =
    selectedRole === 1
      ? columns.map((column) => {
        if (column.accessorKey === 'committeeIds') {
          return {
            accessorKey: 'committeeIds',
            header: 'Committees',
            Edit: () => null,
          };
        }
        return column;
      })
      : columns;
  return (
    <div className="m-4">
      <Table
        isLoading={isLoading}
        columns={updatedColumns}
        enableCreate={false}
        enableGrouping={true}
        data={users}
        title="Users"
        enableEditing={true}
        allowEditAction={() => false}
        additionalEditComponents={(row) => (
          <Fragment>
            {isLoading && <CircularProgress />}
            {error && <Alert severity="error">{error}</Alert>}
          </Fragment>
        )}
        resetOnFormClose={() => {
          setValidationErrors({});
          dispatch(resetUserError());
        }}
        columnVisibility={{
          firstName: false,
          lastName: false,
          id: false,
          schoolId: false,
          phoneNumber: false,
          gender: false,
        }}
        onCreateRowSave={async ({ values, table }) => {
          // values = { ...values, status: !!values.status };
          // console.log({ values });
          // const newValidationErrors = validateUser(values);
          // if (Object.values(newValidationErrors).some((error) => error)) {
          //   setValidationErrors(newValidationErrors);
          //   return;
          // }
          // setValidationErrors({});
          // if ([1, 6].includes(values.roleId)) {
          //   values.committeeIds = [];
          // }
          // const body: any = deleteEmptyKeys(values, [
          //   'representeeId',
          //   'schoolId',
          //   'committeeIds',
          // ]);
          // dispatch(
          //   createUser({
          //     user: body,
          //     onSuccess: () => table.setCreatingRow(null),
          //   })
          // ); //exit creating mode
        }}
        onEditRowSave={async ({ values, table }) => {
          // values = { ...values, status: !!values.status };
          // const newValidationErrors = validateUser(values);
          // if (Object.values(newValidationErrors).some((error) => error)) {
          //   setValidationErrors(newValidationErrors);
          //   return;
          // }
          // setValidationErrors({});
          // if ([1, 6].includes(values.roleId)) {
          //   values.committeeIds = [];
          // }
          // const body: any = deleteEmptyKeys(values, [
          //   'representeeId',
          //   'schoolId',
          //   'committeeIds',
          // ]);
          // body.committeeIds = body.committeeIds.filter(
          //   (id: number) => id !== null
          // );
          // dispatch(
          //   updateUser({
          //     user: body,
          //     onSuccess: () => table.setEditingRow(null),
          //   })
          // ); //exit creating mode
        }}
        tableActions={
          allowEdits
            ? [
              <AdminUserContextProvider
                value={{ enableAdminUsers: isAdminUsers }}
              >
                <AddUserDialog />
              </AdminUserContextProvider>,
              <UploadUsersBulk />,
              <DownloadUsersBulkTemplate />,
            ]
            : []
        }
        rowActions={(row) => <RowActions row={row} allowEdit={allowEdits} />}
      />
    </div>
  );
};

interface MultiSelectEditComponentProps<T extends MRT_RowData> {
  cell: MRT_Cell<T, unknown>;
  column: MRT_Column<T, unknown>;
  row: MRT_Row<T>;
  table: MRT_TableInstance<T>;
}
const MultiSelectEditComponent =
  (committees: Committee[]) =>
    <T extends MRT_RowData>({
      cell,
      column,
      row,
      table,
    }: MultiSelectEditComponentProps<T>) => {
      // const committees = useSelector(committeesListSelector);
      //Combine both committes and groups into options fot eh select
      const options = committees
        .filter((committee) => committee.status)
        .map((committee) => ({
          label: committee.name,
          value: committee.id,
        }));
      console.log({ row, table, val: cell.getValue() });
      if (
        row._valuesCache.roleId === Number(roles.SUPER_ADMIN) ||
        row._valuesCache.roleId === Number(roles.OBSERVER)
      )
        return null;
      return (
        <FormControl fullWidth>
          <InputLabel>Committees</InputLabel>
          <Select
            multiple
            inputProps={{
              shrink: true,
            }}
            label="Committees"
            value={row.original.committeeIds || []}
            onChange={(e) => {
              console.log(e.target.value);
              const updatedRowData = {
                ...row.original,
                committeeIds: e.target.value as string[],
              };
              console.log({ updatedRowData });
              if (row.original.id) {
                table.setEditingRow(createRow(table, updatedRowData));
              } else {
                table.setCreatingRow(createRow(table, updatedRowData));
              }
            }}
            renderValue={(selected) =>
              options
                .filter((option) => selected.includes(option.value))
                .map((option) => option.label)
                .join(', ')
            }
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    };

interface IUploadUsersBulkProps { }
const UploadUsersBulk: React.FC<IUploadUsersBulkProps> = () => {
  const [file, setFile] = useState<File | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const handleClose = () => {
    dispatch(clearImportResponse());
    setOpen(false);
  };

  const importResponse = useSelector(usersImportResponseSelector);
  const isLoading = useSelector(usersIsLoadingSelector);
  const handleImportUsers = () => {
    if (file !== null)
      dispatch(importUsers({ file, onSuccess: () => handleClose() }));
  };
  return (
    <Fragment>
      <Tooltip title="Import users">
        <IconButton onClick={() => setOpen(true)}>
          <CloudUpload />
        </IconButton>
      </Tooltip>
      {open && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Import users from csv</DialogTitle>
          <DialogContent dividers>
            <FileInput
              accept=".csv"
              label="Upload CSV"
              onChange={(evt) => {
                console.log(evt.target.files[0]);
                setFile(evt.target.files[0]);
              }}
            />
            {isLoading && <CircularProgress />}
            {importResponse && (
              <ImportResponseDisplay importResponse={importResponse} />
            )}
          </DialogContent>
          <DialogActions>
            <PrimaryButton
              label="Import Users"
              onClick={handleImportUsers}
              isLoading={isLoading}
            />
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

const ImportResponseDisplay = ({
  importResponse,
}: {
  importResponse: ImportResponse;
}) => {
  return (
    <div
      style={{
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
      }}
    >
      <p className="font-bold underline">Import Summary</p>
      <p className="mt-2">
        <strong>Successfully Added:</strong> {importResponse.successfullyAdded}
      </p>
      {importResponse.errorEmails.length > 0 && (
        <div>
          <strong>Error Emails:</strong>
          <Alert severity="warning">
            {importResponse.errorEmails.map((email: string, index: number) => (
              <li key={index}>{email}</li>
            ))}
          </Alert>
        </div>
      )}
      {importResponse.duplicateEmails?.length > 0 && (
        <div className="mt-2">
          <strong>Duplicate Emails:</strong>
          <Alert severity="warning">
            {importResponse.duplicateEmails.map(
              (email: string, index: number) => (
                <li key={index}>{email}</li>
              )
            )}
          </Alert>
        </div>
      )}
      {Object.entries(importResponse.errorList)?.length > 0 && (
        <div className="mt-2">
          <strong>Error Details:</strong>
          {Object.entries(importResponse.errorList).map(
            ([email, errors], index) => (
              <div key={index}>
                <h4>{email}</h4>
                <Alert severity="error">
                  {errors.map((error, errorIndex) => (
                    <li key={errorIndex}>{error}</li>
                  ))}
                </Alert>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

const DownloadUsersBulkTemplate = () => {
  return (
    <Tooltip title="Download template">
      <IconButton
        color="primary"
        aria-label="download csv"
        component="a"
        href={`${process.env.PUBLIC_URL}/files/import_users_template.csv`}
        download="import_users_template.csv"
      >
        <CloudDownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

interface IRowActions {
  row: MRT_Row<User>;
  allowEdit: boolean;
}

const RowActions: React.FC<IRowActions> = ({ row, allowEdit }) => {
  const dispatch = useDispatch<AppDispatch>();
  const chatAccess = row.original.chatAccess;
  const toggleChatAccess = () => {
    dispatch(
      updateUser({
        user: {
          id: row.original.id,
          eventId: row.original.eventId,
          chatAccess: !chatAccess,
        },
      })
    );
  };

  const handleSendResetPasswordLink = () => {
    // TODO: Implement API call to send reset password link
    console.log(`Send reset password link to user with ID: ${row.original.id}`);
    dispatch(
      resetPassword({
        id: row.original.id,
        onSuccess: () => {
          console.log('Reset password link sent successfully');
        },
      })
    )
  };

  const handleUnblockUser = () => {
    // TODO: Implement API call to unblock user
    console.log(`Unblock user with ID: ${row.original.id}`);
    dispatch(
      unblockUser({
        id: row.original.id,
        onSuccess: () => {
          console.log('User unblocked successfully');
        },
      })
    )
  };

  return (
    <Fragment>
      {/* Chat */}
      {/* <Tooltip title="Chat">
        <IconButton>
          <ForumIcon />
        </IconButton>
      </Tooltip> */}
      {/* Ban */}
      {allowEdit && <AddUserDialog user={row.original} />}
      {allowEdit &&
        (chatAccess ? (
          <ConfirmAction
            onConfirm={toggleChatAccess}
            title="Block Chat"
            description="Are you sure you want to block this user from chat?"
            icon={<SpeakerNotesOffIcon />}
            tooltip="Block Chat"
          />
        ) : (
          <ConfirmAction
            onConfirm={toggleChatAccess}
            title="Unblock Chat"
            tooltip="Unblock Chat"
            description="Are you sure you want to unblock this user for chat?"
            icon={<MarkChatReadIcon />}
            iconColor="success"
          />
        ))}
      <Tooltip title="Send Reset Password Link">
        <IconButton onClick={handleSendResetPasswordLink}>
          <Email />
        </IconButton>
      </Tooltip>
      {row.original.blocked &&
        <Tooltip title="Unblock User">
          <IconButton onClick={handleUnblockUser}>
            <BlockOutlined />
          </IconButton>
        </Tooltip>}
      {/* Delete */}
      {/* <ConfirmAction
        onConfirm={() =>
          dispatch(deleteUser({ id: row.original.id, onSuccess: () => {} }))
        }
      /> */}
    </Fragment>
  );
};

export default Users;
